.blog-pre {
  background-color: #282c34;
  margin-bottom: 3em !important;
  /* -webkit-box-shadow: -10px 0px 13px -7px #000000, 10px 0px 13px -7px #000000,
    35px -23px 2px -16px rgba(0, 0, 0, 0);
  box-shadow: -10px 0px 13px -7px #000000, 10px 0px 13px -7px #000000,
    35px -23px 2px -16px rgba(0, 0, 0, 0); */
  position: relative;
}
.flexcontainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: flex-start;
}
.code-copy-btn {
  color: white;
  position: absolute;
  margin-top: 10px;
  right: 10px;
  top: 0px;
  font-size: 1.5em;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.code-copy-btn:hover {
  transform: scale(1.1);
  opacity: 0.9;
}

.video-container {
  position: relative;
  padding: 20px;
  overflow: hidden;
  background-color: rgb(134, 107, 134);
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 10px;
}

/* Small Screen Media Query */
@media only screen and (max-width: 600px) {
  .video-container {
    padding: 5px;
  }
}

code {
  counter-reset: line;
}

.code-line {
  counter-increment: line;
  position: relative;
  display: block;
  margin-left: 1.5rem;
}

.code-line:before {
  content: counter(line);
  position: absolute;
  margin-left: -1.5rem;
}


/* Markdown table styling */
 table {
  border-spacing: 0 !important;
  border-collapse: collapse !important;
  border-color: inherit !important;
  display: block !important;
  margin: 0 auto !important;
  width: 100% !important;
  max-width: 100% !important;
  overflow: auto !important;
}

 tbody,
 td,
 tfoot,
 th,
 thead,
 tr {
  border-color: inherit !important;
  border-style: solid !important;
  border-width: 2px !important;
  padding: 0.5rem;
}
