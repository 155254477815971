/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  font-family: "Verdana", cursive, sans-serif;
  color: #ffffff;
} */

/* body {
  background-color: #7cc6fe;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
} */

.app {
  color: #ffffff;
  background-color: #41523b;
  /* min-width: 250px; */
  min-height: 200px;
  height: min-content;
  border-radius: 15px;
  padding: 20px;
  /* box-shadow: 10px 10px 42px 0px rgba(0, 0, 0, 0.75); */
  display: flex;
  justify-content: space-evenly;
}

.score-section {
  display: flex;
  font-size: 24px;
  align-items: center;
  gap: 25px;
}

/* QUESTION/TIMER/LEFT SECTION */
.question-section {
  width: 100%;
  position: relative;
}

.question-count {
  margin-bottom: 20px;
}

.question-count span {
  font-size: 28px;
}

.question-text {
  margin-bottom: 12px;
}

.timer-text {
  background: rgb(230, 153, 12);
  padding: 15px;
  margin-top: 20px;
  margin-right: 20px;
  border: 5px solid rgb(255, 189, 67);
  border-radius: 15px;
  text-align: center;
}

/* ANSWERS/RIGHT SECTION */
.answer-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

button {
  width: 100%;
  font-size: 16px;
  color: #ffffff;
  background-color: #90a998;
  border-radius: 15px;
  display: flex;
  padding: 5px;
  justify-content: flex-start;
  align-items: center;
  border: 5px solid #4e6247;
  cursor: pointer;
}

.correct {
  background-color: #2f922f;
}

.incorrect {
  background-color: #ff3333;
}

button:hover {
  background-color: #3c4c3d;
}

button:focus {
  outline: none;
}

button svg {
  margin-right: 5px;
}

/* Media Query Screen Size Phone */

@media screen and (max-width: 600px) {
  .app {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .question-section {
    width: 100%;
  }

  .answer-section {
    width: 100%;
  }

  .timer-text {
    margin: 0;
  }
}
